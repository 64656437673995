body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebarLink, .sidebarLink:visited {
  color: var(--gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 15px;
}

.sidebarLink:hover, .sidebarLinkActive  {
  color: var(--primary) !important;
  text-decoration: none;
}

.sticky {
  border-collapse: separate;
  border-spacing: 0;
}

.sticky th { 
  position: sticky; 
  top: 0px; 
  border-top: 2px solid black;
  background-color: white;
}

.sticky tr td:last-child, .sticky tr th:last-child {
  border-right: 2px solid black;
} 

.sticky tr th:first-child {
  border-left: 2px solid black;
} 

#root {
  height: 100%;
}



