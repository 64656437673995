.shake {
    transform-origin: center;
    animation: shake 0.2s infinite;
}

@keyframes shake {
  0%, 50%, 100%  { transform: translateX(0px);  }
  25% { transform: translateX(3px);  }
  75% { transform: translateX(-3px);  }
}

